#playerStats {
    width: 15rem;
    height: 4rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 3rem;
    left: 3rem;
}

h1 {
    margin: 0;
    font-size: 1.5rem;
    white-space: nowrap;
}

h2 {
    margin: 0;
    font-size: 1rem;
    white-space: nowrap;
}

#playerStats__lines {
    display: flex;
    align-items: center;
}

#playerStats__lines__thick {
    display: block;
    background-color: white;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    height: 0.2rem;
    width: 8.75rem;
}

#playerStats__lines__thin {
    display: block;
    background-color: white;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    height: 0.1rem;
    width: 6.2rem;
}

@media (max-width: 1100px) {
    #playerStats {
        left: 40%;
        transform: translateX(-50%);
        width: 8rem;
        height: 2.5rem;
        top: 2rem;
    }
    h1 {
        font-size: 0.8rem;
    }
    h2, h3, p {
        font-size: 0.7rem;
    }
}

#playerStats h2 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    animation: 
        typing 3.5s steps(40, end),
        blink-caret .75s step-end infinite;
}

@keyframes typing {
    from { width: 0; }
    to { width: 57ch; }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
}

